requirejs(["jquery", "libary/slick.min", "libary/TweenMax.min", "libary/mask"], function ($) {
  $('.review-items').slick({
    dots: false,
    arrow: true,
    infinite: false,
    speed: 300,
    margin: 20,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
  $('.video-items').slick({
    dots: false,
    arrow: true,
    infinite: false,
    speed: 300,
    margin: 20,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
  $('.group-content-items').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    speed: 750,
    fade: true,
    dots: false,
    adaptiveHeight: true,
    asNavFor: '.group-title-items'
  });
  $('.group-title-items').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.group-content-items',
    dots: false,
    arrows: false,
    focusOnSelect: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 2
      }
    }]
  });
  $('.training-form form').each(function (index, item) {
    var self = item,
        str;
    $(item).find('.but-action').on('click', function (event) {
      event.preventDefault();
      $(self).find('.but-action').prop('disabled', true);
      str = $(this).parents('form').serialize();
      $.ajax({
        type: "POST",
        url: "https://api.fc-impuls.com/api/new_contact",
        data: str,
        success: function success(msg) {
          if (msg == 'ok') {
            $('.popup-modal.js-successbox .content-modal').html('<h5>Успещно</h5><p>Сообщение отправлено.</p>');
            $('.popup-modal.js-successbox').toggleClass('_show');
            $('.overlay').toggleClass('_show');
            document.getElementById('form-bottom').reset();
            $(self).find('.but-action').prop('disabled', false);
          } else {
            $('.popup-modal.js-successbox').addClass('js-errorsuccessbox');
            $('.popup-modal.js-successbox .content-modal').html('<h5>Ошибка</h5><p>Сообщение не отправлено, убедитесь в правильности заполнение полей</p>');
            $('.popup-modal.js-successbox').toggleClass('_show');
            $('.overlay').toggleClass('_show');
            $(self).find('.but-action').prop('disabled', false);
          }
        }
      });
      return false;
    });
  });
  $('.group-title-items').slick('slickGoTo', 1);
  var $layer_1 = $('.slick-slide img'),
      $players = $('.players'),
      $container = $('body'),
      container_w = $container.width(),
      container_h = $container.height();
  $('.group-container').on('mousemove.parallax', function (event) {
    var pos_x = event.pageX,
        pos_y = event.pageY,
        left = 0,
        top = 0;
    left = container_w / 2 - pos_x;
    top = container_h / 2 - pos_y;
    TweenMax.to($layer_1, 5, {
      css: {
        transform: 'translateX(' + left / 7 + 'px) translateY(' + top / 12 + 'px)'
      },
      ease: Expo.easeOut,
      overwrite: 'all'
    });
  });
  $('body').on('click', '.menu-mobile', function (e) {
    e.preventDefault();
    $(this).parent().toggleClass('active');
  });
  $('body').on('click', '.city ul li a', function (e) {
    var tel;
    e.preventDefault();
    $('.city').find('.active').removeClass('active');
    $(this).addClass('active');
    tel = $(this).data('tel');
    $('.call-phone').attr('href', 'tel:' + tel);
  });
  $('body').on('click', '.contact-popup', function (e) {
    e.preventDefault();
    $('.popup-modal.contact').addClass('_show');
    $('.overlay').addClass('_show');
  });
  $('body').on('click', '.close', function (e) {
    $('.popup-modal').removeClass('_show');
    $('.overlay').removeClass('_show');
  });
  $('body').on('click', '.overlay', function (e) {
    $('.popup-modal').removeClass('_show');
    $('.overlay').removeClass('_show');
  }); // через 5 сек остановить повторы

  setInterval(function () {
    $('.call-phone').addClass('active');
  }, 4000); // через 5 сек остановить повторы

  setTimeout(function () {
    $('.call-phone').removeClass('active');
  }, 6000); //
  // $('header .banner').on('mousemove.parallax', function(event) {
  //     var pos_x = event.pageX,
  //         pos_y = event.pageY,
  //         left  = 0,
  //         top   = 0;
  //
  //     left = container_w / 2 - pos_x;
  //     top  = container_h / 5 - pos_y;
  //
  //     TweenMax.to($players, 5, {
  //             css: {
  //                 transform: 'translateX(' + left / 20 + 'px) translateY(' + top / 20 + 'px)'
  //             },
  //             ease:Expo.easeOut,
  //             overwrite: 'all'
  //         });
  // });

  var currentNumber1 = $('.about .number1').text(),
      currentNumber2 = $('.about .number2').text(),
      currentNumber3 = $('.about .number3').text(),
      currentNumber4 = $('.about .number4').text(),
      currentNumber5 = $('.about .number5').text(),
      currentNumber6 = $('.about .number6').text(); // $({numberValue: 0}).animate({numberValue: parseInt(currentNumber1)}, {
  //     duration: 12000,
  //     easing: 'linear',
  //     step: function() {
  //         $('.about .number1').text(Math.ceil(this.numberValue));
  //     }
  // });

  $({
    numberValue: 0
  }).animate({
    numberValue: parseInt(currentNumber2)
  }, {
    duration: 6000,
    easing: 'linear',
    step: function step() {
      $('.about .number2').text(Math.ceil(this.numberValue));
    }
  });
  $({
    numberValue: 0
  }).animate({
    numberValue: parseInt(currentNumber3)
  }, {
    duration: 6000,
    easing: 'linear',
    step: function step() {
      $('.about .number3').text(Math.ceil(this.numberValue));
    }
  });
  $({
    numberValue: 0
  }).animate({
    numberValue: parseInt(currentNumber4)
  }, {
    duration: 6000,
    easing: 'linear',
    step: function step() {
      $('.about .number4').text(Math.ceil(this.numberValue));
    }
  });
  $({
    numberValue: 0
  }).animate({
    numberValue: parseInt(currentNumber5)
  }, {
    duration: 6000,
    easing: 'linear',
    step: function step() {
      $('.about .number5').text(Math.ceil(this.numberValue));
    }
  });
  $({
    numberValue: 0
  }).animate({
    numberValue: parseInt(currentNumber6)
  }, {
    duration: 6000,
    easing: 'linear',
    step: function step() {
      $('.about .number6').text(Math.ceil(this.numberValue));
    }
  });
});